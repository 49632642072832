import React from "react"
import "./sections.css"

const Section1 = () => {

    return <section className="refund-sec1">
        <div className="content">
            <div className="content-text">
                <p>Application fees are non-refundable. Please refer to our adoption contract for specified terms on our return policy.</p>
            </div>
        </div>
    </section>

}



const Sections = {
    Section1
}

export default Sections