import React from 'react'
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby'
import "./how-to-help.css"
const HowToHelp = () => {

    return <section className="sec5">
        <div className="2xl:container w-full px-4 mx-auto">
            <div className="content">
                <h3>HOW TO HELP</h3>
                <p>There are many ways to help DFW Lab Rescue through volunteering, fostering, and donations. Check out our generous donors who are matching donations:</p>
                <div className="client-logos">
                    <StaticImage layout="fixed" alt="partner 1" className="img" src="../../images/ge-logo.png" />
                    <StaticImage layout="fixed" alt="partner 2" className="img" src="../../images/att-logo.png" />
                    <StaticImage layout="fixed" alt="partner 3" className="img" src="../../images/bp-logo.png" />
                    <StaticImage layout="fixed" alt="partner 4" className="img" src="../../images/aon-logo.png" />
                    <StaticImage layout="fixed" alt="partner 5" className="img" src="../../images/coca-cola-logo.png" />
                    <StaticImage layout="fixed" alt="partner 6" className="img" src="../../images/sap-logo.png" />
                    <StaticImage layout="fixed" alt="partner 7" className="img" src="../../images/jj-logo.png" />
                    <StaticImage layout="fixed" alt="partner 8" className="img" src="../../images/halliburton-logo.png" />
                    <StaticImage layout="fixed" alt="partner 9" className="img" src="../../images/anne-mcgilvary-logo.png" />
                    <StaticImage layout="fixed" alt="partner 10" className="img" src="../../images/avon-logo.png" />
                    <StaticImage layout="fixed" alt="partner 11" className="img" src="../../images/bank-of-america-logo.png" />
                    <StaticImage layout="fixed" alt="partner 12" className="img" src="../../images/bristol-logo.png" />
                    <StaticImage layout="fixed" alt="partner 13" className="img" src="../../images/ibm-logo.png" />
                    <StaticImage layout="fixed" alt="partner 14" className="img" src="../../images/soros-logo.png" />
                </div>
                <Link className="btn-primary" to="/donate/">Donate or Start A Fundraiser</Link>
            </div>

        </div>
    </section>
}

export default HowToHelp