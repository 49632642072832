import React from "react";
import "./inner-banner.css";
const InnerBanner = ({subtitle,title,image,description,content,theme}) => {
    return <section style={{backgroundImage:`url(${image})`,backgroundSize:'cover',backgroundPosition:"center top"}} className={"inner-banner "+(theme?theme:"")}>
        <div className="content">
            {subtitle && <h4>{subtitle}</h4>}
            <h1>{title}</h1>
            {description && <div className="desc"><p>{description}</p></div>}
            {content && <div className="desc" dangerouslySetInnerHTML={{__html:content}}/>}
        </div>
    </section>
}

export default InnerBanner